<template>
  <v-card>
    <v-card-title class="px-2">
      <Comeback /> {{ $t('AccountReceivableReport') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="exportLoading"
        class="d-none d-md-block"
        :disabled="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
      <v-btn
        color="primary"
        class="d-block d-md-none"
        :loading="exportLoading"
        :disabled="exportLoading"
        icon
        fab
        outlined
        @click="exportExcel"
      >
        <v-icon>{{ mdiFileExcelOutline }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="px-2">
      <v-col cols="12" md="6" lg="3" class="py-0">
        <v-text-field
          v-model="searchtext"
          dense
          outlined
          :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
          @keypress.enter="addPayload()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.order_id`]="{ item }">
        <router-link
          v-if="item.order_id"
          :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
          class="font-weight-medium text-decoration-none"
          target="_blank"
        >
          {{ item.order_id }}
        </router-link>
      </template>
      <template v-slot:[`item.orderpay_modify`]="{ item }">
        {{ item.orderpay_modify | sumdatetime }}
      </template>
      <template v-slot:[`item.orderpay_all`]="{ item }">
        <span :class="item.orderpay_all == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.orderpay_all }}
        </span>
      </template>
      <template v-slot:[`item.orderpay_balance`]="{ item }">
        <span :class="item.orderpay_all == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.orderpay_balance }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import { reportOrderPay, removeComma } from '../useExcel'
import { sumdatetime } from '@/plugins/filters'
import Comeback from '../Comeback.vue'

export default {
  components: {
    Comeback,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    // basic report
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const searchtext = ref('')
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 120 },
      { text: i18n.t('customer'), value: 'customer_name', width: 220 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 120 },
      {
        text: i18n.t('net_amount'),
        value: 'order_totalpay',
        width: 180,
        align: 'end',
      },
      { text: i18n.t('last_paid'), value: 'orderpay_modify', width: 170 },
      { text: i18n.t('Payment'), value: 'shop_bank_name', width: 170 },
      {
        text: i18n.t('period'),
        value: 'orderpay_period',
        width: 80,
        align: 'end',
      },
      {
        text: i18n.t('payments'),
        value: 'orderpay_current',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('total_payment'),
        value: 'orderpay_all',
        width: 150,
        align: 'end',
      },
      {
        text: i18n.t('outstanding_balance'),
        value: 'orderpay_balance',
        width: 150,
        align: 'end',
      },
    ])

    onMounted(() => {
      addPayload()
    })
    const addPayload = () => {
      payload.value = {
        searchtext: searchtext.value,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportOrderPay(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const a = dataTableList.value.map(item => {
        delete item.order_id_pri

        return item
      })
      const dataExport = await removeComma(JSON.parse(JSON.stringify(a)))
      const fileName = `${i18n.t('report_accounts_receivable')}  .xlsx`

      //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('report_accounts_receivable')} `],
        [
          '#',
          `${i18n.t('receipt_code')}`,
          `${i18n.t('customer')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('net_amount')}`,
          `${i18n.t('last_paid')}`,
          `${i18n.t('Payment')}`,
          `${i18n.t('period')}`,
          `${i18n.t('payments')}`,
          `${i18n.t('total_payment')}`,
          `${i18n.t('outstanding_balance')}`,
          `${i18n.t('comment')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'customer_name',
          'customer_tel',
          'order_totalpay',
          'orderpay_modify',
          'shop_bank_name',
          'orderpay_period',
          'orderpay_current',
          'orderpay_all',
          'orderpay_balance',
          'orderpay_comment',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols = [
        { wch: 8 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 40 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('report_accounts_receivable')}`)

      /* พิมร์ files */

      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      payload,
      loading,
      exportLoading,
      options,
      dataTableList,
      searchtext,
      columns,
      addPayload,
      searchReport,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>
